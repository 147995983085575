/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {getUserDetail} from '../../modules/accounts/core/_request'
import {useAuth} from '../../modules/auth'
// import {
//   MixedWidget2,
//   MixedWidget10,
//   MixedWidget11,
//   ListsWidget2,
//   ListsWidget3,
//   ListsWidget4,
//   ListsWidget5,
//   ListsWidget6,
//   TablesWidget5,
//   TablesWidget10,
//   MixedWidget8,
// } from '../../../_metronic/partials/widgets'

const DashboardPage: FC = () => {
  const {currentUser} = useAuth()
  const [myWidget, setMyWidget] = useState()

  useEffect(() => {
    getUserDetail(currentUser?.id || '')
      .then((res: any) => {
        if (res.dashboardWidget) setMyWidget(res.dashboardWidget)
        else setMyWidget(undefined)
      })
      .catch((e) => {
        setMyWidget(undefined)
      })
  }, [])

  return myWidget ? (
    <iframe src={myWidget} style={{width: '100%', minHeight: '100%'}}></iframe>
  ) : (
    <></>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
