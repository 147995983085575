import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'

// Define a type for the slice state
interface CurrentUserState {
  data: any,
  theme: any
}

// Define the initial state using that type
const initialState: CurrentUserState = {
  data: null,
  theme: 'light'
}

export const matterSlice = createSlice({
  name: 'currentUserMDL',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<any>) => {
      state.data = action.payload
    },
    setUserTheme: (state, action: PayloadAction<any>) => {
      state.theme = action.payload
    }
  }
})

export const { 
    setUserData,
    setUserTheme
} = matterSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.matterMDL.value

export default matterSlice.reducer

