import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../store'

// Define a type for the slice state
interface MatterState {
  selectedSpace: any
  selectedClient: any
  matterList: any
}

// Define the initial state using that type
const initialState: MatterState = {
  selectedSpace: null,
  selectedClient: null,
  matterList: []
}

export const matterSlice = createSlice({
  name: 'matterMDL',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSpace: (state, action: PayloadAction<any>) => {
        state.selectedSpace = action.payload
    },
    setClient: (state, action: PayloadAction<any>) => {
        state.selectedClient = action.payload
    },
    setMatters: (state, action: PayloadAction<any>) => {
      state.matterList = action.payload
    }
  }
})

export const { 
    setSpace,
    setClient,
    setMatters
} = matterSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.matterMDL.value

export default matterSlice.reducer

