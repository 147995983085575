import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword, requestUpdatePassword, validateResetCode} from '../core/_requests'
import Swal from 'sweetalert2'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

const passwordFormValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/,
      'Check password must contain 10 characters mix with uppercase, lowercase, number and symbols'
    ),
  passwordConfirmation: Yup.string()
    .required('Password confirmation is required')
    .when('newPassword', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('newPassword')],
        "Password and Confirm Password didn't match"
      ),
    }),
})

interface IUpdatePassword {
  newPassword: string
  passwordConfirmation: string
}

const updatePassword: any = {
  newPassword: '',
  passwordConfirmation: '',
}

export function ForgotPassword() {
  const navigate = useNavigate()
  const [pageReady, setPageReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then((res: any) => {
            if (res.data && res.data.isSuccess) {
              Swal.fire({
                title: 'Success',
                text: 'Reset code successfully sent to your email',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
              })
            } else if (res.data && !res.data.isSuccess) {
              Swal.fire({
                title: 'Error',
                text: res.data.message,
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
              })
            } else {
              Swal.fire({
                title: 'Error',
                text: 'Password failed to reset please try again.',
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
              })
            }
            setHasErrors(false)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  const [resetCode, setResetCode] = useState()
  const [resetCodeValid, setResetCodeValid] = useState(false)
  const [resetCodeValidResponse, setResetCodeValidResponse] = useState<any>({
    isSuccess: false,
    email: null,
  })
  const [passwordUpdateData, setPasswordUpdateData] = useState<any>(updatePassword)
  const [showPasswordForm, setPasswordForm] = useState<boolean>(false)
  const [loading2, setLoading2] = useState(false)
  const formik2 = useFormik<IUpdatePassword>({
    initialValues: {
      ...passwordUpdateData,
    },
    validationSchema: passwordFormValidationSchema,
    onSubmit: (values) => {
      setLoading2(true)
      setTimeout(() => {
        requestUpdatePassword(resetCodeValidResponse.email, values.newPassword)
          .then((res: any) => {
            if (res.data && res.data.isSuccess) {
              Swal.fire({
                title: 'Saved',
                text: 'Password has reset successfully.',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
              })
              navigate(`/auth`)
            } else {
              Swal.fire({
                title: 'Error',
                text: 'Password failed to reset please try again.',
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
              })
            }
          })
          .catch(() => {
            Swal.fire({
              title: 'Error',
              text: 'Password failed to reset please try again.',
              icon: 'error',
              showConfirmButton: false,
              timer: 1500,
            })
          })
        setPasswordUpdateData(values)
        setLoading2(false)
        setPasswordForm(false)
      }, 1000)
    },
  })

  useEffect(() => {
    const params: any = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop: any) => searchParams.get(prop),
    })

    if (params.code) {
      setResetCode(params.code)
      validateResetCode(params.code)
        .then((res: any) => {
          const {isSuccess} = res.data
          setResetCodeValidResponse(res.data)
          if (isSuccess) setResetCodeValid(true)
          else setResetCodeValid(false)
        })
        .catch(() => {
          setResetCodeValid(false)
        })
        .finally(() => {
          setPageReady(true)
        })
    } else {
      setPageReady(true)
    }
  }, [])

  return pageReady ? (
    <>
      {resetCode && resetCodeValid && resetCodeValidResponse.isSuccess ? (
        <form
          onSubmit={formik2.handleSubmit}
          id='kt_signin_change_password'
          className='form'
          noValidate
        >
          <div className='row mb-1'>
            <div className='col-lg-12'>
              <div className='fv-row mb-0'>
                <label htmlFor='newpassword' className='form-label fs-6 fw-bolder mb-3'>
                  New Password
                </label>
                <input
                  type='password'
                  id='newpassword'
                  placeholder='New Password'
                  {...formik2.getFieldProps('newPassword')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik2.touched.newPassword && formik2.errors.newPassword,
                    },
                    {
                      'is-valid': formik2.touched.newPassword && !formik2.errors.newPassword,
                    }
                  )}
                />
                {formik2.touched.newPassword && formik2.errors.newPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>{formik2.errors.newPassword}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='col-lg-12' style={{marginTop: 10}}>
              <div className='fv-row mb-0'>
                <label htmlFor='confirmpassword' className='form-label fs-6 fw-bolder mb-3'>
                  Confirm New Password
                </label>
                <input
                  type='password'
                  id='confirmpassword'
                  placeholder='Confirm New Password'
                  {...formik2.getFieldProps('passwordConfirmation')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid':
                        formik2.touched.passwordConfirmation && formik2.errors.passwordConfirmation,
                    },
                    {
                      'is-valid':
                        formik2.touched.passwordConfirmation &&
                        !formik2.errors.passwordConfirmation,
                    }
                  )}
                />
                {formik2.touched.passwordConfirmation && formik2.errors.passwordConfirmation && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>
                      {formik2.errors.passwordConfirmation}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='form-text mb-5'>
            Must contain 10 characters mix with uppercase, lowercase, number and symbols.
          </div>

          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button id='kt_password_submit' type='submit' className='btn btn-primary me-2 px-6'>
              {!loading2 && 'Update Password'}
              {loading2 && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth'>
              <button
                onClick={() => {
                  // setPasswordForm(false)
                }}
                id='kt_password_cancel'
                type='button'
                className='btn btn-color-gray-400 btn-active-light-primary px-6'
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
      ) : (
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>Forgot Password ?</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-400 fw-bold fs-4'>
              Enter your email to reset your password.
            </div>
            {/* end::Link */}
          </div>

          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold text-danger'>
                Sorry, looks like there are some errors detected, please try again.
              </div>
            </div>
          )}
          {/* end::Title */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6 required'>Email</label>
            <input
              type='email'
              placeholder='Email Address'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block text-danger'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-lg btn-primary fw-bolder me-4'
            >
              <span className='indicator-label'>Submit</span>
              {loading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-light-primary fw-bolder'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Cancel
              </button>
            </Link>{' '}
          </div>
          {/* end::Form group */}
        </form>
      )}
    </>
  ) : (
    <></>
  )
}
