import axios, {AxiosResponse} from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/me`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/create-user`
export const REQUEST_PASSWORD_URL = `${API_URL}/validate-email`
export const REQUEST_PASSWORD_RESET = `${API_URL}/reset-password`
export const REQUEST_RESET_CODE_VALIDATE = `${API_URL}/validate-reset-code`
export const ACCEPT_USER_INVITE = `${API_URL}/accept-user-invite`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username: email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  middlename: string,
  company: string,
  contactNo: string,
  isAdmin: boolean
) {
  return axios.post(REGISTER_URL, {
    email,
    firstname: firstname,
    lastname: lastname,
    password,
    contactNo,
    isAdmin,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, email)
}

export function validateResetCode(code: string) {
  return axios.post<{result: boolean}>(REQUEST_RESET_CODE_VALIDATE, code)
}

export function requestUpdatePassword(email: string, password: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_RESET, {
    email,
    password,
  })
}

export function getUserByToken(token: string) {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

export const getUserInvitation = (code: any): Promise<any> => {
  return axios
    .get(ACCEPT_USER_INVITE + `?code=${code}`)
    .then((response: AxiosResponse<any>) => response.data)
}
